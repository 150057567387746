import { Link } from "gatsby"
import React from "react"

const MillionCard = props => {
  const {
    episodeNumber,
    audioDuration,
    title,
    season,
    slug,
    image,
    guestName,
    guestTitle,
    superPodGuestTextTheme,
  } = props.data
  const slugprefix =
    props.slug === "superpod-bytes" || props.slug === "limited-edition"
      ? props.slug
      : `superpod/${props.slug}`
  return (
    <Link to={`/${slugprefix}/${slug}`}>
      <div
        className="bytes-card-show"
        style={{
          backgroundImage: `url(${image.url})`,
          backgroundSize: "cover",
        }}
      >
        <div className="center-image">
          <img
            src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/d7HRhD7QS7Wnq2J7gmMD"
            alt="play button"
          />
        </div>
      </div>
    </Link>
  )
}

export default MillionCard
